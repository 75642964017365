'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _draftJs = require('draft-js');

/**
 * Returns a new EditorState where the Selection is at the end.
 *
 * This ensures to mimic the textarea behaviour where the Selection is placed at
 * the end. This is needed when blocks (like stickers or other media) are added
 * without the editor having had focus yet. It still works to place the
 * Selection at a specific location by clicking on the text.
 */
var moveSelectionToEnd = function moveSelectionToEnd(editorState) {
  var content = editorState.getCurrentContent();
  var blockMap = content.getBlockMap();

  var key = blockMap.last().getKey();
  var length = blockMap.last().getLength();

  var selection = new _draftJs.SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length
  });

  return _draftJs.EditorState.acceptSelection(editorState, selection);
};

exports.default = moveSelectionToEnd;