// @flow
import { CharacterMetadata, EditorState, ContentState } from 'draft-js';

// @flow
var ATOMIC = "atomic";
var UNSTYLED = "unstyled";
var UNORDERED_LIST_ITEM = "unordered-list-item";
var ORDERED_LIST_ITEM = "ordered-list-item";
var IMAGE = "IMAGE";

// @flow
/**
 * Creates atomic blocks where they would be required for a block-level entity
 * to work correctly, when such an entity exists.
 * Note: at the moment, this is only useful for IMAGE entities that Draft.js
 * injects on arbitrary blocks on paste.
 */

var preserveAtomicBlocks = function preserveAtomicBlocks(content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var perservedBlocks = blockMap.filter(function (block) {
    var text = block.getText();
    var entityKey = block.getEntityAt(0);
    var shouldPreserve = entityKey && ["📷", " ", "📷 "].includes(text);
    return shouldPreserve;
  }).map(function (block) {
    return block.set("type", ATOMIC);
  });

  if (perservedBlocks.size !== 0) {
    return content.merge({
      blockMap: blockMap.merge(perservedBlocks)
    });
  }

  return content;
};
/**
 * Resets atomic blocks to have a single-space char and no styles.
 * This is how they are stored by Draft.js by default.
 */

var resetAtomicBlocks = function resetAtomicBlocks(content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var blocks = blockMap;
  var normalisedBlocks = blocks.filter(function (block) {
    return block.getType() === ATOMIC && (block.getText() !== " " || block.getInlineStyleAt(0).size !== 0);
  }).map(function (block) {
    // Retain only the first character, and remove all of its styles.
    var chars = block.getCharacterList().slice(0, 1).map(function (char) {
      var newChar = char;
      char.getStyle().forEach(function (type) {
        newChar = CharacterMetadata.removeStyle(newChar, type);
      });
      return newChar;
    });
    return block.merge({
      text: " ",
      characterList: chars
    });
  });

  if (normalisedBlocks.size !== 0) {
    blocks = blocks.merge(normalisedBlocks);
  }

  return content.merge({
    blockMap: blocks
  });
};
/**
 * Removes atomic blocks for which the entity type isn't allowed.
 */

var removeInvalidAtomicBlocks = function removeInvalidAtomicBlocks(allowlist
/*: $ReadOnlyArray<{ type: string }>*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();

  var isValidAtomicBlock = function isValidAtomicBlock(block) {
    if (block.getType() !== ATOMIC) {
      return true;
    }

    var entityKey = block.getEntityAt(0);
    var isValid;

    if (entityKey) {
      var type = content.getEntity(entityKey).getType();
      isValid = allowlist.some(function (t) {
        return t.type === type;
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  var filteredBlocks = blockMap.filter(isValidAtomicBlock);

  if (filteredBlocks.size !== blockMap.size) {
    return content.merge({
      blockMap: filteredBlocks
    });
  }

  return content;
};

// @flow
/**
 * Removes blocks that have a non-zero depth, and aren't list items.
 * Happens with Apple Pages inserting `unstyled` items between list items.
 */

var removeInvalidDepthBlocks = function removeInvalidDepthBlocks(content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();

  var isValidDepthBlock = function isValidDepthBlock(block) {
    var isListBlock = [UNORDERED_LIST_ITEM, ORDERED_LIST_ITEM].includes(block.getType());
    return isListBlock || block.getDepth() === 0;
  };

  var filteredBlocks = blockMap.filter(isValidDepthBlock);

  if (filteredBlocks.size !== blockMap.size) {
    return content.merge({
      blockMap: filteredBlocks
    });
  }

  return content;
};
/**
 * Changes block type and depth based on the block's text. – some word processors
 * add a specific prefix within the text, eg. "· Bulleted list" in Word 2010.
 * Also removes the matched text.
 * This is meant first and foremost for list items where the list bullet or numeral
 * ends up in the text. Other use cases may not be well covered.
 */

var preserveBlockByText = function preserveBlockByText(rules
/*: $ReadOnlyArray<{
    test: string,
    type: string,
    depth: number,
  }>*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var blocks = blockMap.filter(function (block) {
    return block.getType() === "unstyled";
  }).map(function (block) {
    var text = block.getText();
    var newBlock = block;
    var match;
    var matchingRule = rules.find(function (rule) {
      match = new RegExp(rule.test).exec(text);
      return match !== null;
    });

    if (matchingRule && match && match[0]) {
      var _text = block.getText();

      var entity = block.getEntityAt(0); // Special case – do not convert the block if there is an entity at the start, and the matching text is the full block’s text.
      // This can happen in Word for equations, which are injected as images with text "📷 ".

      if (entity && match[0] === _text) {
        return newBlock;
      } // Unicode gotcha:
      // At the moment, Draft.js stores one CharacterMetadata in the character list
      // for each "character" in an astral symbol. "📷" has a length of 2, is stored with two CharacterMetadata instances.
      // What matters is that we remove the correct number of chars from both
      // the text and the List<CharacterMetadata>. So – we want to use the ES5 way of counting
      // a string length.
      // See https://mathiasbynens.be/notes/javascript-unicode.


      var sliceOffset = match[0].length; // Maintain persistence in the list while removing chars from the start.
      // https://github.com/facebook/draft-js/blob/788595984da7c1e00d1071ea82b063ff87140be4/src/model/transaction/removeRangeFromContentState.js#L333

      var chars = block.getCharacterList();
      var startOffset = 0;

      while (startOffset < sliceOffset) {
        chars = chars.shift();
        startOffset++;
      }

      newBlock = newBlock.merge({
        type: matchingRule.type,
        depth: matchingRule.depth,
        text: _text.slice(sliceOffset),
        characterList: chars
      });
    }

    return newBlock;
  });
  return blocks.size === 0 ? content : content.merge({
    blockMap: blockMap.merge(blocks)
  });
};
/**
 * Resets the depth of all the content to at most max.
 */

var limitBlockDepth = function limitBlockDepth(max
/*: number*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var changedBlocks = blockMap.filter(function (block) {
    return block.getDepth() > max;
  }).map(function (block) {
    return block.set("depth", max);
  });
  return changedBlocks.size === 0 ? content : content.merge({
    blockMap: blockMap.merge(changedBlocks)
  });
};
/**
 * Converts all block types not present in the list to unstyled.
 * Also sets depth to 0 (for potentially nested list items).
 */

var filterBlockTypes = function filterBlockTypes(allowlist
/*: $ReadOnlyArray<string>*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var changedBlocks = blockMap.filter(function (block) {
    return !allowlist.includes(block.getType());
  }).map(function (block) {
    return block.merge({
      type: UNSTYLED,
      depth: 0
    });
  });
  return changedBlocks.size === 0 ? content : content.merge({
    blockMap: blockMap.merge(changedBlocks)
  });
};

// @flow
/**
 * Removes all styles not present in the list.
 */

var filterInlineStyles = function filterInlineStyles(allowlist
/*: $ReadOnlyArray<string>*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var blocks = blockMap.map(function (block) {
    var altered = false;
    var chars = block.getCharacterList().map(function (char) {
      var newChar = char;
      char.getStyle().filter(function (type) {
        return !allowlist.includes(type);
      }).forEach(function (type) {
        altered = true;
        newChar = CharacterMetadata.removeStyle(newChar, type);
      });
      return newChar;
    });
    return altered ? block.set("characterList", chars) : block;
  });
  return content.merge({
    blockMap: blockMap.merge(blocks)
  });
};

// @flow
/**
 * Clones entities in the entityMap, so each range points to its own entity instance.
 * This only clones entities as necessary – if an entity is only referenced
 * in a single range, it won't be changed.
 */

var cloneEntities = function cloneEntities(content
/*: ContentState*/
) {
  var newContent = content;
  var blockMap = newContent.getBlockMap();
  var encounteredEntities = []; // Marks ranges that need cloning, because their entity has been encountered previously.

  var shouldCloneEntity = function shouldCloneEntity(firstChar) {
    var key = firstChar.getEntity();

    if (key) {
      if (encounteredEntities.includes(key)) {
        return true;
      }

      encounteredEntities.push(key);
    }

    return false;
  }; // We're going to update blocks that contain ranges pointing at the same entity as other ranges.


  var blocks = blockMap.map(function (block) {
    var newChars = block.getCharacterList();
    var altered = false; // Updates ranges for which the entity needs to be cloned.

    var updateRangeWithClone = function updateRangeWithClone(start, end) {
      var key = newChars.get(start).getEntity();
      var entity = newContent.getEntity(key);
      newContent = newContent.createEntity(entity.getType(), entity.getMutability(), entity.getData());
      var newKey = newContent.getLastCreatedEntityKey(); // Update all of the chars in the range with the new entity.

      newChars = newChars.map(function (char, i) {
        if (start <= i && i <= end) {
          return CharacterMetadata.applyEntity(char, newKey);
        }

        return char;
      });
      altered = true;
    };

    block.findEntityRanges(shouldCloneEntity, updateRangeWithClone);
    return altered ? block.set("characterList", newChars) : block;
  });
  return newContent.merge({
    blockMap: blockMap.merge(blocks)
  });
};
/*:: import type { BlockNode } from "draft-js/lib/BlockNode.js.flow" */

/**
 * Filters entity ranges (where entities are applied on text) based on the result of
 * the callback function. Returning true keeps the entity range, false removes it.
 * Draft.js automatically removes entities if they are not applied on any text.
 */

var filterEntityRanges = function filterEntityRanges(filterFn
/*: (
    content: ContentState,
    entityKey: string,
    block: BlockNode,
  ) => boolean*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  /*
   * Removes entities from the character list if the entity isn't enabled.
   * Also removes image entities placed outside of atomic blocks, which can happen
   * on paste.
   * A better approach would probably be to split the block where the image is and
   * create an atomic block there, but that's another story. This is what Draft.js
   * does when the copy-paste is all within one editor.
   */

  var blocks = blockMap.map(function (block) {
    var altered = false;
    var chars = block.getCharacterList().map(function (char) {
      var entityKey = char.getEntity();

      if (entityKey) {
        var shouldRemove = !filterFn(content, entityKey, block);

        if (shouldRemove) {
          altered = true;
          return CharacterMetadata.applyEntity(char, null);
        }
      }

      return char;
    });
    return altered ? block.set("characterList", chars) : block;
  });
  return content.merge({
    blockMap: blockMap.merge(blocks)
  });
};
/**
 * Keeps all entity types (images, links, documents, embeds) that are enabled.
 */

var shouldKeepEntityType = function shouldKeepEntityType(allowlist
/*: $ReadOnlyArray<{ type: string }>*/
, type
/*: string*/
) {
  return allowlist.some(function (e) {
    return e.type === type;
  });
};
/**
 * Removes invalid images – they should only be in atomic blocks.
 * This only removes the image entity, not the camera emoji (📷) that Draft.js inserts.
 */

var shouldRemoveImageEntity = function shouldRemoveImageEntity(entityType
/*: string*/
, blockType
/*: string*/
) {
  return entityType === IMAGE && blockType !== ATOMIC;
};
/**
 * Filters entities based on the data they contain.
 */

var shouldKeepEntityByAttribute = function shouldKeepEntityByAttribute(entityTypes
/*: $ReadOnlyArray<{
    type: string,
    allowlist?: {
      [attribute: string]: string | boolean,
    },
    // Deprecated. Use allowlist instead. Will be removed in a future release.
    whitelist?: {
      [attribute: string]: string | boolean,
    },
  }>*/
, entityType
/*: string*/
, data
/*: {}*/
) {
  var config = entityTypes.find(function (t) {
    return t.type === entityType;
  }); // If no allowlist is defined, the filter keeps the entity.

  var allowlist = config && config.allowlist ? config.allowlist : config && config.whitelist ? config.whitelist : {};
  var isValid = Object.keys(allowlist).every(function (attr) {
    var check = allowlist[attr];

    if (typeof check === "boolean") {
      var hasData = data.hasOwnProperty(attr);
      return check ? hasData : !hasData;
    }

    return new RegExp(check).test(data[attr]);
  });
  return isValid;
};
/**
 * Filters data on an entity to only retain what is allowed.
 * This is crucial for IMAGE and LINK, where Draft.js adds a lot
 * of unneeded attributes (width, height, etc).
 */

var filterEntityData = function filterEntityData(entityTypes
/*: $ReadOnlyArray<{
    type: string,
    attributes?: $ReadOnlyArray<string>,
  }>*/
, content
/*: ContentState*/
) {
  var newContent = content;
  var entities = {};
  newContent.getBlockMap().forEach(function (block) {
    block.findEntityRanges(function (char) {
      var entityKey = char.getEntity();

      if (entityKey) {
        var entity = newContent.getEntity(entityKey);
        entities[entityKey] = entity;
      }
    });
  });
  Object.keys(entities).forEach(function (key) {
    var entity = entities[key];
    var data = entity.getData();
    var config = entityTypes.find(function (t) {
      return t.type === entity.getType();
    });
    var allowlist = config ? config.attributes : null; // If no allowlist is defined, keep all of the data.

    if (!allowlist) {
      return data;
    }

    var newData = allowlist.reduce(function (attrs, attr) {
      // We do not want to include undefined values if there is no data.
      if (data.hasOwnProperty(attr)) {
        attrs[attr] = data[attr];
      }

      return attrs;
    }, {});
    newContent = newContent.replaceEntityData(key, newData);
  });
  return newContent;
};

// @flow
/**
 * Replaces the given characters by their equivalent length of spaces, in all blocks.
 */

var replaceTextBySpaces = function replaceTextBySpaces(characters
/*: $ReadOnlyArray<string>*/
, content
/*: ContentState*/
) {
  var blockMap = content.getBlockMap();
  var blocks = blockMap.map(function (block) {
    var text = block.getText(); // Only replaces the character(s) with as many spaces as their length,
    // so that style and entity ranges are left undisturbed.
    // If we want to completely remove the character, we also need to filter
    // the corresponding CharacterMetadata entities.

    var newText = characters.reduce(function (txt, char) {
      return txt.replace(new RegExp(char, "g"), " ".repeat(char.length));
    }, text);
    return text !== newText ? block.set("text", newText) : block;
  });
  return content.merge({
    blockMap: blockMap.merge(blocks)
  });
};

// @flow
/**
 * Applies the new content to the editor state, optionally moving the selection
 * to be on a valid block, inserting one if needed.
 * See https://github.com/thibaudcolas/draftjs-filters/issues/27.
 */

var applyContentWithSelection = function applyContentWithSelection(editorState
/*: EditorState*/
, content
/*: ContentState*/
, nextContent
/*: ContentState*/
) {
  // If the content is the same before/after, return the state unaltered.
  if (nextContent === content) {
    return editorState;
  } // If the block map is empty, insert a new unstyled block and put the selection on it.


  if (nextContent.getBlockMap().size === 0) {
    return EditorState.moveFocusToEnd(EditorState.set(editorState, {
      currentContent: ContentState.createFromText("")
    }));
  }

  var nextState = EditorState.set(editorState, {
    currentContent: nextContent
  });
  var selection = editorState.getSelection();
  var anchorKey = selection.getAnchorKey();
  var anchorBlock = nextContent.getBlockForKey(anchorKey); // We only support moving collapsed selections, which is the only behavior of selections after paste.
  // And if the anchor block is valid, no need to move the selection.

  var shouldKeepSelection = !selection.isCollapsed() || !!anchorBlock;

  if (shouldKeepSelection) {
    return nextState;
  }

  var nextKeys = nextContent.getBlockMap().keySeq(); // Find the first key whose successor is different in the old content (because a block was removed).
  // Starting from the end so the selection is preserved towards the last preserved block in the filtered region.

  var nextAnchorKey = nextKeys.reverse().find(function (k) {
    return content.getKeyAfter(k) !== nextContent.getKeyAfter(k);
  }); // If the selection was already misplaced before paste, we do not move it.

  if (nextAnchorKey) {
    var nextSelectedBlock = nextContent.getBlockForKey(nextAnchorKey);
    var blockEndOffset = nextSelectedBlock.getText().length;
    var nextSelection = selection.merge({
      anchorKey: nextAnchorKey,
      focusKey: nextAnchorKey,
      anchorOffset: blockEndOffset,
      focusOffset: blockEndOffset
    });
    return EditorState.acceptSelection(nextState, nextSelection);
  }

  return nextState;
};

// @flow
/*:: import type { EditorState as EditorStateType } from "draft-js"*/

/*:: type FilterOptions = {
  // List of allowed block types. unstyled and atomic are always included.
  blocks: $ReadOnlyArray<string>,
  // List of allowed inline styles.
  styles: $ReadOnlyArray<string>,
  // List of allowed entities.
  entities: $ReadOnlyArray<{
    // Entity type, eg. "LINK"
    type: string,
    // Allowed attributes. Other attributes will be removed. If this is omitted, all attributes are kept.
    attributes?: $ReadOnlyArray<string>,
    // Refine which entities are kept by matching acceptable values with regular expression patterns.
    // It's also possible to use "true" to signify that a field is required to be present,
    // and "false" for fields required to be absent.
    // If this is omitted, all entities are kept.
    allowlist?: {
      [attribute: string]: string | boolean,
    },
    // Deprecated. Use allowlist instead. Will be removed in a future release.
    whitelist?: {
      [attribute: string]: string | boolean,
    },
  }>,
  // Maximum amount of depth for lists (0 = no nesting).
  maxNesting: number,
  // Characters to replace with whitespace.
  whitespacedCharacters: Array<string>,
  // Optional: Rules used to automatically convert blocks from one type to another
  // based on the block’s text. Also supports setting the block depth.
  // Defaults to the filters’ built-in block prefix rules.
  blockTextRules?: $ReadOnlyArray<{
    // A regex as a string, to match against block text, e.g. "^(◦|o |o\t)".
    test: string,
    // The type to convert the block to if the test regex matches.
    type: string,
    // The depth to set (e.g. for list items with different prefixes per depth).
    depth: number,
  }>,
}*/

var BLOCK_PREFIX_RULES = [{
  // https://regexper.com/#%5E(%C2%B7%20%7C%E2%80%A2%5Ct%7C%E2%80%A2%7C%F0%9F%93%B7%20%7C%5Ct%7C%20%5Ct)
  test: "^(· |•\t|•|📷 |\t| \t)",
  type: "unordered-list-item",
  depth: 0
}, // https://regexper.com/#%5E(%E2%97%A6%7Co%20%7Co%5Ct)
{
  test: "^(◦|o |o\t)",
  type: "unordered-list-item",
  depth: 1
}, // https://regexper.com/#%5E(%C2%A7%20%7C%EF%82%A7%5Ct%7C%E2%97%BE)
{
  test: "^(§ |\t|◾)",
  type: "unordered-list-item",
  depth: 2
}, {
  // https://regexper.com/#%5E1%7B0%2C1%7D%5Cd%5C.%5B%20%5Ct%5D
  test: "^1{0,1}\\d\\.[ \t]",
  type: "ordered-list-item",
  depth: 0
}, {
  // Roman numerals from I to XX.
  // https://regexper.com/#%5Ex%7B0%2C1%7D(i%7Cii%7Ciii%7Civ%7Cv%7Cvi%7Cvii%7Cviii%7Cix%7Cx)%5C.%5B%20%5Ct%5D
  test: "^x{0,1}(i|ii|iii|iv|v|vi|vii|viii|ix|x)\\.[ \t]",
  type: "ordered-list-item",
  depth: 2
}, {
  // There is a clash between this and the i., v., x. roman numerals.
  // Those tests are executed in order though, so the roman numerals take priority.
  // We do not want to match too many letters (say aa.), because those could be actual text.
  // https://regexper.com/#%5E%5Ba-z%5D%5C.%5B%20%5Ct%5D
  test: "^[a-z]\\.[ \t]",
  type: "ordered-list-item",
  depth: 1
}];
/**
 * Applies filtering and preservation operations to the editor content,
 * to restrict it to supported patterns.
 * Will not alter the editor state if there are no changes to make.
 */

var filterEditorState = function filterEditorState(options
/*: FilterOptions*/
, editorState
/*: EditorStateType*/
) {
  var blocks = options.blocks,
      styles = options.styles,
      entities = options.entities,
      maxNesting = options.maxNesting,
      whitespacedCharacters = options.whitespacedCharacters,
      _options$blockTextRul = options.blockTextRules,
      blockTextRules = _options$blockTextRul === void 0 ? BLOCK_PREFIX_RULES : _options$blockTextRul;

  var shouldKeepEntityRange = function shouldKeepEntityRange(content, entityKey, block) {
    var entity = content.getEntity(entityKey);
    var entityData = entity.getData();
    var entityType = entity.getType();
    var blockType = block.getType();
    return shouldKeepEntityType(entities, entityType) && shouldKeepEntityByAttribute(entities, entityType, entityData) && !shouldRemoveImageEntity(entityType, blockType);
  }; // Order matters. Some filters may need the information filtered out by others.


  var filters = [// 1. clean up blocks.
  removeInvalidDepthBlocks, preserveBlockByText.bind(null, blockTextRules), limitBlockDepth.bind(null, maxNesting), // 2. reset styles and blocks.
  filterInlineStyles.bind(null, styles), // Add block types that are always enabled in Draft.js.
  filterBlockTypes.bind(null, blocks.concat([UNSTYLED, ATOMIC])), // 4. Process atomic blocks before processing entities.
  preserveAtomicBlocks, resetAtomicBlocks, // 5. Remove entity ranges (and linked entities)
  filterEntityRanges.bind(null, shouldKeepEntityRange), // 6. Remove/filter entity-related matters.
  removeInvalidAtomicBlocks.bind(null, entities), filterEntityData.bind(null, entities), // 7. Clone entities for which it is necessary.
  cloneEntities, // 8. Finally, do text operations.
  replaceTextBySpaces.bind(null, whitespacedCharacters)];
  var content = editorState.getCurrentContent();
  var nextContent = filters.reduce(function (c, filter
  /*: (ContentState) => ContentState*/
  ) {
    return filter(c);
  }, content);
  return applyContentWithSelection(editorState, content, nextContent);
};

export { applyContentWithSelection, cloneEntities, filterBlockTypes, filterEditorState, filterEntityData, filterEntityRanges, filterInlineStyles, limitBlockDepth, preserveAtomicBlocks, preserveBlockByText, removeInvalidAtomicBlocks, removeInvalidDepthBlocks, replaceTextBySpaces, resetAtomicBlocks, shouldKeepEntityByAttribute, shouldKeepEntityType, shouldRemoveImageEntity };
